/* If the screen size is 768px wide or less, hide the element */
@media only screen and (max-width: 200px) {
    div.horizontal-container {
        display: none;
    }

    div.coming-soon {
        display: flex;
    }
}

html {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    overflow-y: hidden;
    height: 100%;
    font-family: "Poppins", sans-serif;
}

#root {
    width: 100%;
    height: 100%;
}

h2 {
    font-weight: bold;
    font-size: 24px;
    margin: 0px;
}

.coming-soon {
    display: none;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    text-align: center;
    justify-content: space-evenly;
    background-color: #B82327;
    color: #fff;
}

.coming-soon-heading {
    font-weight: 600;
    font-size: 2rem;
    margin: auto auto;
}

.horizontal-flex {
    display: flex;
    flex-direction: row;
    width: max-content;
    height: 100%;
}

.vertical-centre {
    margin: auto 0px;
}

.horizontal-container {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

/*
 * Sidebar
 */

.sidebar {
    z-index: 1;
    width: 50px;
    min-width: 50px;
    display: flex;
    flex-direction: column;
    color: #fff;
    background-color: #2b2c38;
    font-weight: bold;
    font-size: 1.75rem;
    justify-content: space-between;
    padding-bottom: 4rem;

    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
    width: 0px;
}

.vertical-container {
    display: flex;
    flex-direction: column;
}

.nav-sidebar {
    width: 100%;
    z-index: 1;
}

.nav-sidebar > li > div {
    position: relative;
    display: inline-block;
    padding: 0.3rem 0 0.5rem 0.6rem;
    width: 100%;
    z-index: 1;
}

.nav-sidebar > li > div:hover {
    cursor: pointer;
    background-color: #aaaaaa;
    color: #fff;
}

.nav-sidebar > .active > div {
    background-color: #B82327;
    color: #ffffff;
}

hr {
    border-color: #B82327;
    border-width: 2px;
    border-style: solid;
    width: 25%;
    text-align: left;
    margin: 0px 0px 10px 30px;
    border-radius: 2px;
}

.small-underline {
    width: 40px;
    margin: 5px 0;
}

.small-underline-right {
    width: 40px;
    margin: 0 16px;
    text-align: right;
}

#context-menu {
    display: none;
    padding: 0px 20px;
    padding-bottom: 10px;
    font-weight: normal;
    font-size: 16px;
    color: #fff;
    background-color: #2b2c38;
}

::-webkit-scrollbar {
    width: 0px;
}

.panel {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 600;
    border-color: #fff;
    border-width: 2px;
    border-style: solid;
    padding: 5px 30px;
    color: #fff;
}

.lead {
    display: block;
    margin-bottom: 5px;
    font-size: 1.25rem;
    font-weight: 600;
    color: #fff;
    width: 100%;
    padding: 2px 20px;
}

.outline-button {
    border-radius: 0;
}

.form-group {
    margin-bottom: 0.5rem;
    color: #fff;
}

small {
    color: #fff;
}

.control-label {
    font-size: 14px;
}

.form-control {
    width: 100px;
    height: max-content;
    padding: 0.2rem 0.75rem;
}

/* [type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect 
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0.3rem;
  top: 0.1rem;
  width: 1.1em;
  height: 1.1em;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #fff;
  border-radius: 2px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
/* checked mark aspect 
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: "\2713\0020";
  position: absolute;
  top: 0.13em;
  left: 0.4em;
  font-size: 1.2em;
  line-height: 0.8;
  color: #5A6268;
  transition: all 0.2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
}
/* checked mark aspect changes 
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
} */

.texture-tiles {
    width: 100%;
}

#texture-context-container {
    position: absolute;
    top: 60px;
    left: 50px;
    z-index: 2;
    width: max-content;
    max-width: 250px;
    background-color: #ffffff;
    border-bottom-right-radius: 10px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
    width: 0px;
}

#floorTexturesDiv {
    margin: 0;
    padding-bottom: 10px;
    height: 100%;
    background-color: #2b2c38;
}

#wallTextures {
    margin: 0;
    padding-bottom: 10px;
    height: 100%;
    background-color: #2b2c38;
}

.texture-panel {
    margin-bottom: 5px;
    font-weight: 600;
    padding: 5px 20px;
    color: #ffffff;
}

.texture-panel-heading {
    font-size: 16px;
}

.texture-select-thumbnail {
    display: inline-block;
    width: 5rem;
    min-width: 100px;
    margin: 2px;
}

.texture-select-thumbnail:hover {
    cursor: pointer;
}

.thumbnail {
    width: 100%;
    height: 5rem;
    border-radius: 10px;
    padding: 2px;
    object-fit: cover;
    border: 1px solid #fff;
}

/*
 * Main content
 */

#loading-modal {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba(50, 50, 50, 0.9);
}

#loading-modal h1 {
    text-align: center;
    margin-top: 30%;
    color: #fff;
}

.right-container {
    z-index: 1;
    flex: 1 1 auto;
}

#camera-controls {
    position: absolute;
    bottom: 20px;
    right: 0;
    padding: 0 20px;
    text-align: right;
}

.view-switcher {
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translate(-50%);
}

.basic-button {
    padding: 3px 8px 6px 8px;
    text-align: center;
    margin: 1px;
}

.lean-button {
    padding: 0px;
    text-align: center;
    margin: 1px;
}

.file-button {
    display: inline-block;
    cursor: pointer;
    padding: 3px 8px 6px 8px;
}

label {
    margin-bottom: 0px;
}

.vertical-controls-container {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-basis: fit-content;
    width: fit-content;
    vertical-align: middle;
}

#floorplanner {
    display: none;
}

#floorplanner-controls {
    position: absolute;
    right: 0;
    top: 40px;
    margin: 50px 0;
    padding: 0 20px;
    width: fit-content;
}

#draw-walls-hint {
    position: absolute;
    right: 20px;
    bottom: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    padding: 5px 10px;
    z-index: 10;
    display: none;
}

.icon-text-button {
    display: inline-flex;
    padding: 1px 8px 3px 8px;
    align-content: center;
    margin: 1px;
}

.icon-centre {
    margin: 2px 1px 1px 1px;
}

.text-centre {
    margin: 1px;
    padding-top: 2px;
    padding-left: 2px;
}

/* ADD ITEMS */
#add-items {
    justify-content: start;
    padding-bottom: 100px;
    height: 100%;
    margin: 10px;
    display: none;
    flex: 1 1 auto;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
    width: 0px;
}

.item-card {
    width: 16rem;
    min-width: 15rem;
    margin: 20px;
    display: inline-block;
    border: 4px solid #fff;
    border-radius: 0;
    box-shadow: 10px 10px 5px #aaaaaa;
    transition: 0.1s all ease-in-out;
}

.item-card:hover {
    transform: scale(1.05);
    box-shadow: 15px 15px 10px #aaaaaa;
    cursor: pointer;
}

.item-card-img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
}

.item-card-title-outer {
    padding: 6px;
    background-color: #000000;
    width: max-content;
    margin: auto 0 auto auto;
}

.item-card-title {
    width: max-content;
    font-size: 1.2rem;
    background-color: #000000;
    padding: 0.1rem 1rem;
    border-color: #fff;
    border-width: 2px;
    border-style: dashed;
    margin: auto 0 auto auto;
}

.active-title {
    border-style: solid;
}

.more-details-link {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%);
    width: max-content;
    font-size: 1.2rem;
    background-color: #000000;
    padding: 0.1rem 1rem;
    border-color: #fff;
    border-width: 2px;
    border-style: dashed;
    margin: auto 0 auto auto;
}

.item-card-title:hover {
    border-style: solid;
}

button#print {
    position: absolute;
    z-index: 999;
    top: 15px;
    right: 10px;
}

.item-card-body {
    padding: 0;
}

/*
 * Top Bar
 */
.top-bar {
    z-index: 1;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0 20px;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    background: #2b2c38;
    color: #fff;
}

.company-name {
    display: inline-flex;
    color: #B82327;
    margin: auto 10px;
    border-color: #B82327;
    border-width: 2px;
    border-style: solid;
    padding: 5px 10px;
}

.top-bar-logo {
    width: 10rem;
    height: 100%;
}

.top-bar-login-button {
    margin-top: 10px;
    margin-right: 30px;
    border-style: none;
    font-weight: 600;
    padding: 0.25rem 0.75rem;
    color: #fff;
}

.top-bar-login-button:active,
.top-bar-login-button:focus {
    border-style: none;
    outline: none;
    box-shadow: none;
}

.name-display {
    margin: auto 30px;
    border-color: #5a6268;
    border-width: 2px;
    border-style: dashed;
    font-weight: 600;
    padding: 0.25rem 0.75rem;
    color: #5a6268;
}

.custom-light-button {
    background-color: transparent;
    font-size: 1.5rem;
    border-style: none;
    outline: none;
    box-shadow: none;
    color: #fff;
}

.custom-light-button:hover {
    border-style: none;
    outline: none;
    box-shadow: none;
}

.custom-light-button:focus,
.custom-light-button:focus {
    border-style: none;
    outline: none;
    box-shadow: none;
}

.login-modal {
    margin: auto;
    display: flex;
    flex-direction: column;
    min-width: 400px;
}

.login-modal-heading {
    margin: 1px;
    padding-top: 5px;
    padding-left: 2px;
}

.login-form-space {
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    padding: 10px 10px;
    color: #5a6268;
    width: 100%;
    justify-content: space-around;
}

.login-modal-tab {
    width: 40%;
    text-align: center;
    margin: auto;
}

.login-form {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    width: 100%;
}

.login-form-input-space {
    display: flex;
    flex-direction: row;
    margin: auto 5px;
    width: 100%;
}

.login-form-icon {
    display: flex;
    padding: 0.55rem 0.7rem;
    margin: auto;
    border-width: 1px;
    border-style: solid;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
}

.login-form-input {
    margin: 5px 10px 5px 1px;
    padding: 4px;
    flex: 1 1 auto;
}

.modal-plain-text {
    margin: 5px;
    font-weight: 600;
}

.small-alert {
    font-size: 0.7rem;
    margin: 5px 5px 0 5px;
    padding: 2px;
    padding-left: 0.4rem;
}

.large-alert {
    font-size: 0.9rem;
    margin: 5px 5px 0 5px;
    padding: 2px;
    padding-left: 0.4rem;
}

.login-submit-button {
    margin: 5px;
    border-radius: 0%;
    font-weight: 600;
}

.nav-pills .nav-link {
    background-color: #ffffff;
    color: #5a6268;
    font-weight: 600;
    padding-bottom: 5px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: #ffffff;
    color: #5a6268;
    font-weight: 600;
    border-bottom: 4px #B82327 solid;
    border-radius: 0;
}

.save-file-space {
    display: flex;
    flex-direction: row;
}

.save-file-text {
    margin: auto 5px;
}

.save-file-input {
    flex: 1 1 auto;
    margin: auto 5px;
}

.save-file-container {
    width: 100%;
}

.save-file-list-block {
    display: inline-block;
}

.save-file-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 200px;
    max-width: 400px;
    margin: 5px;
    padding: 0.4rem;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
}

.save-file-list-item:hover {
    border-width: 1px;
    border-style: solid;
    border-color: #5a6268;
    cursor: pointer;
}

.save-file-name {
    margin: auto 1px;
}

.info-text {
    margin-top: 0.6rem;
    font-size: 0.8rem;
}

.thumb {
    width: 100%;
}

.basic-button {
    background: #2b2c38;
    border: 1px solid #fff;
}

.tab-content > .active {
    display: block;
    height: 80vh;
    overflow: scroll;
}

.description {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}
.login-form-space table {
    width: 100%;
}
.login-form-space form tr {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
}
