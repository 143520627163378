  /* Style the close button */
  #saveModal .close {
    color: #c9d1d9;
  }
  
  /* Style the modal title */
  #saveModal .modal-title {
    font-size: 18px;
    font-weight: 600;
  }
  
  /* Style the input fields */
  #saveModal input[type="text"],
  #saveModal input[type="email"],
  #saveModal textarea {
    background-color: #ffffff;
    border: 1px solid #30363d;
    border-radius: 3px;
    color: #000000;
    padding: 6px 8px;
    width: 100%;
  }
  
  /* Style the file input field */
  #saveModal input[type="file"] {
    color: #000000;
  }
  
  /* Style the form submit button */
  #saveModal .login-submit-button {
    width: 100%;
    background-color: #B82327;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    margin-top: 16px;
    padding: 6px 12px;
    transition: background-color 0.2s;
  }
  
  #saveModal .login-submit-button:hover {
    background-color: #e31219;
  }
  
  /* Style the save button */
  #saveModal .save-file-container {
    margin-top: 0;
  }
  
  #saveModal .save-file-container button {
    background-color: #B82327;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    margin-top: 16px;
    padding: 6px 12px;
    transition: background-color 0.2s;
  }
  
  #saveModal .save-file-container button:hover {
    background-color: #e31219;;
  }
  .submit-form {
    width: 98%;
  }
